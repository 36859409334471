import Splide from "@splidejs/splide";
import fslightbox from "fslightbox";
class Slider {
  constructor() {
    this.initSlider();
  }

  initSlider = () => {
    const splide = new Splide(".splide", {
      rewind: true,
      padding: "5rem",
      pagination: false,

      breakpoints: {
        580: {
          padding: "0 rem",
          pagination: false,
          rewind: true,
        },
      },
    });

    splide.mount();
  };
}

export default Slider;

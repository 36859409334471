import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// const drawSvg = require("./DrawSVGPlugin.min");

gsap.registerPlugin(ScrollTrigger);

class Animation {
  constructor() {
    this.subTitles = document.querySelectorAll(".section-title");
    this.description = document.querySelectorAll(".descripton");
    this.icons = document.querySelectorAll(".icon-to-draw");
    this.galleryImages = document.querySelectorAll(".gallery-image");

    this.setSubTitlesScrollTrigger();
    this.setDescriptionAnimation();
    this.handleDrawSVG();
    this.setGalleryAnimation();
  }

  setSubTitlesScrollTrigger = () => {
    gsap.set(this.subTitles, { opacity: 0, y: 50 });

    ScrollTrigger.batch(this.subTitles, {
      onEnter: (subTitles) => {
        gsap.to(subTitles, { opacity: 1, y: 0, duration: 0.5 });
      },
    });
  };

  setDescriptionAnimation = () => {
    gsap.set(this.description, { opacity: 0, y: 100 });

    ScrollTrigger.batch(this.description, {
      onEnter: (description) => {
        gsap.to(description, { opacity: 1, y: 0, duration: 0.8 });
      },
    });
  };

  handleDrawSVG = () => {
    gsap.set(this.icons, { opacity: 0, y: 100 });

    ScrollTrigger.batch(this.icons, {
      onEnter: (icon) => {
        gsap.to(icon, { opacity: 1, y: 0, duration: 0.7, stagger: { each: 0.3 } });
      },
    });
  };

  setGalleryAnimation = () => {
    gsap.set(this.galleryImages, { opacity: 0, scale: 0 });

    ScrollTrigger.batch(this.galleryImages, {
      onEnter: (image) => {
        gsap.to(image, { opacity: 1, scale: 1, duration: 0.5 });
      },
    });
  };
}

export default Animation;

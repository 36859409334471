class Slider {
  constructor() {
    this.slideTimeChange = 5000;
    this.images = document.querySelectorAll(".slider-image");
    this.activeSlide = "";
    this.currentSlideNumer = 0;
    this.howMuchSlies = this.images.length - 1;
    this.activeDot = document.querySelector(".dot--active");
    this.dots = document.querySelectorAll(".dot");

    this.timeOut;

    this.getActiveSlide();
    this.handleSlider();
    this.initDotsListener();
  }

  handleSlider = () => {
    const nextSlide = this.getNextSlide();

    this.changeSlide(nextSlide);
  };

  getNextSlide = () => {
    if (this.currentSlideNumer + 1 > this.howMuchSlies) {
      this.currentSlideNumer = 0;
      return this.images[0];
    }

    this.currentSlideNumer++;
    return this.images[this.currentSlideNumer];
  };

  changeSlide = (image, immediately = false) => {
    const index = [...this.images].indexOf(image);

    const nextDot = this.dots[index];

    let timeOutFireTime = 0;

    immediately === false ? (timeOutFireTime = this.slideTimeChange) : (timeOutFireTime = 0);

    this.timeOut = setTimeout(() => {
      this.toggleClass("slider-active", this.activeSlide);
      this.toggleClass("dot--active", this.activeDot);

      this.activeSlide = image;
      this.activeDot = nextDot;

      this.toggleClass("slider-active", image);
      this.toggleClass("dot--active", nextDot);

      this.handleSlider();
    }, timeOutFireTime);
  };

  getActiveSlide = () => {
    this.images.forEach((item) => {
      if (item.classList.contains("slider-active")) {
        this.activeSlide = item;
      }
    });
  };

  toggleClass = (classToToggle, element) => {
    element.classList.toggle(classToToggle);
  };

  handleDotClick = ({ currentTarget }) => {
    const index = currentTarget.dataset.slide;
    const imageIndex = [...this.images].indexOf(this.activeSlide);

    if (imageIndex === parseInt(index)) return;

    clearTimeout(this.timeOut);
    const image = this.images[index];

    this.changeSlide(image, true);
  };

  initDotsListener = () => {
    this.dots.forEach((dot) => {
      dot.addEventListener("click", (e) => {
        this.handleDotClick(e);
      });

      dot.addEventListener("touchstart", (e) => {
        this.handleDotClick(e);
      });
    });
  };
}

export default Slider;
